<template>
  <div class="modal default-modal modal-open">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container">
          <a
            href=""
            class="close inner"
            @click.prevent="$router.push($route.path)"
          ></a>

          <div class="modal-content">
            <!-- <h3>{{ $t("orders.details_order") }}</h3> -->
            <h3>{{ $t("orders.details_error") }}</h3>

            <form class="payment-form">
              <p class="form-text">{{ $t("orders.paid_error_description") }}</p>

              <div class="buttons auto-width">
                <input
                  type="submit"
                  name="cancel"
                  class="cancel"
                  :value="$t('orders.button_cancel')"
                  @click.prevent="$router.push($route.path)"
                />
                <input
                  type="submit"
                  name="cancel"
                  class="success"
                  :value="$t('orders.button_error_pay')"
                  @click.prevent="tryAgain"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "ErrorPaymentContent",
  props: ["item"],
  methods: {
    ...mapActions("order", {
      completeOrderGuid: "errorOrderGuidBuyContent"
    }),
    ...mapMutations("order", {
      setEntityOrder: "SET_ENTITY"
    }),
    tryAgain() {
      this.setEntityOrder(["showModalBuyContent", true]);
      this.$router.push(this.$route.path);
    }
  },
  mounted() {
    this.completeOrderGuid({
      guid: this.$route.query.guid,
      state: "canceledByPassenger"
    });
  }
};
</script>

<style scoped></style>
