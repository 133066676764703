<template>
  <div class="modal default-modal modal-open">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container">
          <a
            href=""
            class="close inner"
            @click.prevent="cancel()"
          ></a>

          <div class="modal-content">
            <h3>{{ $t("orders.details_order") }}</h3>

            <form class="payment-form">
              <div class="field-row">
                <p class="label">
                  {{
                    item.paymentType === "content"
                      ? $t("orders.content")
                      : $t("orders.section")
                  }}
                </p>
                <p class="read-only-input">
                  {{
                    item.paymentType === "content"
                      ? item.title
                      : item.collections
                      ? item.collections[0].name
                      : ""
                  }}
                </p>
              </div>

              <div class="field-row flex-container">
                <div>
                  <p class="label">{{ $t("orders.time") }}</p>
                  <p class="read-only-input">{{ getTime }}</p>
                </div>
                <div>
                  <p class="label">{{ $t("orders.data") }}</p>
                  <p class="read-only-input">{{ getFullDate }}</p>
                </div>
              </div>

              <div class="field-row">
                <p class="label">{{ $t("orders.payment_method") }}</p>
                <p class="read-only-input">{{ $t("orders.card") }}</p>
              </div>

              <div class="field-row">
                <p class="label">{{ $t("orders.number_order") }}</p>
                <p class="read-only-input">
                  {{ contentOrderNumber }}
                </p>
              </div>

              <div class="field-row">
                <p class="label">{{ $t("orders.price") }}</p>
                <p class="price">{{ item.price }} ₽</p>
              </div>

              <p class="form-text" v-html="$t('orders.paid_description1')"></p>

              <p class="label">{{ $t("orders.wishes") }}</p>

              <div class="buttons one-button">
                <input
                  type="submit"
                  name="cancel"
                  class="success"
                  :value="$t('orders.play')"
                  @click.prevent="cancel()"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import events from "@/mixins/events";

export default {
  name: "SuccessPaymentContent",
  props: ["item"],
  mixins: [events],
  methods: {
    ...mapActions("order", {
      completeOrderGuid: "completeOrderGuidBuyContent"
    }),
    ...mapMutations("order", {
      setEntityOrder: "SET_ENTITY"
    }),
    generateRandomString() {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var charactersLength = characters.length;
      for (var i = 0; i < 3; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    numGenerate() {
      return `${Math.floor(Math.random() * 1000)}`;
    },
    cancel() {
      this.$router.push(this.$route.path);
      window.location.reload();
    }
  },
  computed: {
    ...mapState("order", {
      contentOrderNumber: state => state.contentOrderNumber
    }),
    getTime() {
      var date = new Date();

      return `${date.getHours()}:${date.getMinutes()} `;
    },
    getFullDate() {
      var date = new Date();

      return `${
        date.getUTCDate() <= 9 ? "0" + date.getUTCDate() : date.getUTCDate()
      }.${
        date.getUTCMonth() + 1 <= 9
          ? "0" + (date.getUTCMonth() + 1)
          : date.getUTCMonth() + 1
      }.${date.getUTCFullYear()} `;
    }
  },
  async mounted() {
    if (!this.contentOrderNumber) {
      this.setEntityOrder([
        "contentOrderNumber",
        this.generateRandomString() + "-" + this.numGenerate()
      ]);
    }

    await this.completeOrderGuid({
      guid: this.$route.query.guid,
      state: "completed"
    }).then(async personalData => {
      await this._buyItemEvent(this.item, {
        ...personalData,
        status: "Success"
      });
    });
  }
};
</script>

<style scoped></style>
