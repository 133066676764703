var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('player',{attrs:{"data":_vm.contentData,"advertise-data":_vm.advertiseData},on:{"showModal":_vm.showModalAction}}),_c('section',{staticClass:"section section-video-description"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-ml-12 col-s-24"},[_c('h1',{},[_vm._v(" "+_vm._s(_vm.contentData.title)+" ")]),_c('p',{staticClass:"short-info"},[_vm._v(" "+_vm._s(_vm.contentData.country)+" • "+_vm._s(_vm.contentData.year)+" • "),(_vm.contentData.contentItems.length)?[_vm._v(" "+_vm._s(_vm.contentData.contentItems[_vm.$route.params.episodes || 0] .durationSec / 60)+" "+_vm._s(_vm.$t("video-info.min"))+" • ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.contentData.age)+"+ ")],2),_c('ul',[_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.actors")))]),_vm._v(" "+_vm._s(_vm.contentData.actors)+" ")]),(
                  _vm.contentData.contentItems.length &&
                    _vm.contentData.contentItems[_vm.$route.params.episodes || 0]
                      .audioTracks.length
                )?_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t("video-info.audioTracks")))]),_vm._l((_vm.contentData.contentItems[
                    _vm.$route.params.episodes || 0
                  ].audioTracks),function(item,index){return _c('div',{key:index},[_vm._v(_vm._s(item.name)),(_vm.contentData.contentItems.length)?[_vm._v(_vm._s(_vm.contentData.contentItems[_vm.$route.params.episodes || 0] .audioTracks[index + 1] ? "," : "")+" ")]:_vm._e()],2)})],2):_vm._e(),_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.directors")))]),_vm._v(" "+_vm._s(_vm.contentData.directors)+" ")]),(
                  _vm.contentData.contentItems.length &&
                    _vm.contentData.contentItems[_vm.$route.params.episodes || 0]
                      .subTitles.length
                )?_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("video-info.subTitles")))]),_vm._l((_vm.contentData.contentItems[
                    _vm.$route.params.episodes || 0
                  ].subTitles),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.name)+_vm._s(_vm.contentData.contentItems[_vm.$route.params.episodes || 0] .subTitles[index + 1] ? "," : ""))])})],2):_vm._e()])]),_c('div',{staticClass:"col-10 offset-2 col-sl-11 offset-sl-1 col-s-24 offset-s-0 col-text"},[_c('p',[_vm._v(_vm._s(_vm.contentData.description))])])])])]),_c('payment-content',{attrs:{"show":_vm.showModalBuyContent,"item":_vm.contentData},on:{"closeModal":_vm.closeModal}}),(_vm.$route.query.payment === 'success' && !!_vm.$route.query.guid)?_c('success-payment-content',{attrs:{"item":_vm.contentData}}):_vm._e(),(_vm.$route.query.payment === 'error' && !!_vm.$route.query.guid)?_c('error-payment-content',{attrs:{"item":_vm.contentData}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }